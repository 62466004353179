import React, {
  forwardRef,
  FormEvent,
  ReactNode,
  useEffect,
  useRef,
  useState
} from "react";

import clsx from "clsx";

import { StaticImage } from "gatsby-plugin-image";

import Button from "../components/button";
import Note from "../components/TextBox/Note";
import H1 from "../components/Typography/H1";


interface HasKids {
  children: ReactNode;
}

interface HasInputs {
  showCorrect: boolean;
}

interface InputProps extends HasInputs {
  className: string;
  correct: string;
}

const Input = ({ className, correct, showCorrect }: InputProps): JSX.Element => {
  const [selected, setSelected] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const classes: string = className
    ? `text-4xl font-bold text-black w-12 h-12 rounded-full outline-none focus:ring-4 focus:ring-gray-700 text-center p-2 ${className}`
    : "text-4xl font-bold text-black w-12 h-12 rounded-full outline-none focus:ring-4 focus:ring-gray-700 text-center p-2";

  const handleChange = (e: FormEvent) => {
    if (!showCorrect) {
      setSelected((e.target as HTMLInputElement).value);
    }
  }

  useEffect(() => {
    if (!inputRef?.current) return;

    if (showCorrect) {
      inputRef.current.value = correct;
    }
  }, [showCorrect]);

  return (
    <input
      className={
        clsx(
          classes,
          {
            "bg-drug-ad-yellow": !showCorrect,
            "bg-red-500": showCorrect && selected !== correct,
            "bg-lime-500": showCorrect && selected === correct
          }
        )}
      onChange={handleChange}
      ref={inputRef}
    />
  );
};

const BlueSpan = ({ children }: HasKids): JSX.Element => {
  return (
    <span className="text-drug-ad-blue">{children}</span>
  );
};

const Man = (): JSX.Element => {
  return (
    <StaticImage
      alt="Man leaning against column talking on phone."
      className="w-full border-r-4 border-drug-ad-blue"
      imgClassName="w-full"
      loading="lazy"
      objectFit="contain"
      src="../images/drug-ad-guy.jpg"
      placeholder="blurred"
    />
  );
};

const Misvastatium = (): JSX.Element => {
  return (
    <div className="pl-5 flex justify-end items-end mt-1 max-w-xl">
      <span className="mt-2 text-drug-ad-blue italic">(misvastatium) 100mg tablets</span>
    </div>
  );
};

const HelpRelieve = ({ showCorrect }: HasInputs): JSX.Element => {
  return (
    <div className="relative flex px-5">
      <Input className="absolute left-0 -ml-12" correct="3" showCorrect={showCorrect} /><h2 className="self-end text-drug-ad-green text-2xl mt-2 font-semibold">Help Relieve Seasonal Allergy Symptoms</h2>
    </div>
  );
};

const AdText = ({ showCorrect }: HasInputs): JSX.Element => {
  return (
    <div className="relative px-5 mb-10">
      <Input className="absolute left-0 -ml-12" correct="6" showCorrect={showCorrect} />

      <p className="mt-5"><BlueSpan>Arbitraer</BlueSpan> is a prescription medicine that helps control seasonal allergy symptoms, like runny nose, sneezing, and itchy, watery eyes. By taking <BlueSpan>Arbitraer, once a day</BlueSpan> you can relieve allergy symptoms for up to 24 hours.</p>
      <p className="mt-5 relative"><Input className="absolute left-0 -ml-16 mt-8" correct="8" showCorrect={showCorrect} />You may begin to experience relief of allergy symptoms 2 hours after taking <BlueSpan>Arbitraer</BlueSpan>.</p>
      <p className="mt-5">You may experience headaches, cold symptoms, coughing, or backaches while using <BlueSpan>Arbitraer</BlueSpan>.</p>
      <p className="mt-5 relative"><Input className="absolute left-0 -ml-16" correct="1" showCorrect={showCorrect} /><BlueSpan>Arbitraer</BlueSpan> is for use in adults 18 and older. <BlueSpan>Arbitraer</BlueSpan> is not for use in children.</p>
      <p className="mt-5 relative"><Input className="absolute left-0 -ml-12" correct="9" showCorrect={showCorrect} />You are encouraged to report negative side effects of prescription drugs to the FDA. Visit www.fda.gov/medwatch, or call 1800 FDA-1088</p>
      <p className="mt-5 text-center text-drug-ad-blue relative"><Input className="absolute left-0 md:-ml-10 lg:ml-0" correct="7" showCorrect={showCorrect} /><span className="underline">See reverse for important information about</span> Arbitraer.</p>
      <p className="mt-5 text-center text-xl relative"><Input className="absolute left-0 md:-ml-14 lg:ml-0" correct="4" showCorrect={showCorrect} />Ask your doctor if <BlueSpan>Arbitraer</BlueSpan> is right for you.</p>
    </div>
  );
};

const LeftSide = ({ showCorrect }: HasInputs): JSX.Element => {
  return (
      <div className="w-2/5">
        <Man />
        <div className="flex flex-col justify-center items-center mt-5 relative">
          <Input className="absolute left-0 top-0 md:ml-10 lg:ml-16" correct="5" showCorrect={showCorrect} />
          <div
            className="bg-drug-ad-blue text-center py-1 px-5 text-white font-semibold"
            style={{ borderRadius: "100%" }}
          >
            ACE
          </div>
          <BlueSpan>Pharmaceuticals</BlueSpan>
          <div className="flex space-x-2">
            <BlueSpan>800-555-5555</BlueSpan>
            <BlueSpan>www.arbitraer.com</BlueSpan>
          </div>
        </div>
      </div>
  );
};

const RightSide = ({ showCorrect }: HasInputs): JSX.Element => {
  return (
    <div className="w-3/5">
      <Arbitraer showCorrect={showCorrect} />
      <Misvastatium />
      <HelpRelieve showCorrect={showCorrect} />
      <AdText showCorrect={showCorrect} />
    </div>
  );
};

const Instructions = (): JSX.Element => {
  return (
    <div className="mt-5 max-w-2xl">
      <h2 className="text-2xl">Instructions</h2>
      <ol>
        <li>Read the list of requirements for prescription drug advertisements.</li>
        <li>In the <BlueSpan>Arbitraer</BlueSpan> advertisement (scroll down), there are 9 yellow circles. Click on each circle and enter the number that matches a prescription drug advertisement requirement.</li>
        <li>When you have entered all 9 requirements, click on the "Check Your Answers" button to compare your answers to the correct answers.</li>
      </ol>
    </div>
  );
};

const Ad = forwardRef<HTMLDivElement, HasInputs>(({ showCorrect }, ref) => {
  return (
    <div className="hidden md:block mt-8 bg-white border-3 border-black" ref={ref}>
      <div className="flex">
        <LeftSide showCorrect={showCorrect} />
        <RightSide showCorrect={showCorrect} />
      </div>
    </div>
  );
});

const RequirementsList = (): JSX.Element => {
  return (
    <div className="mt-10 px-3 max-w-2xl bg-gray-300 rounded">
      <h2 className="text-2xl pt-6 pl-7">Prescription Drug Advertisement Requirements</h2>
      <ol className="px-3 pb-3">
        <li>People shown in the ad need to represent appropriate users of the drug. This ad states that Arbitraer is for adults 18 and older. The picture shows an adult man. It would not be appropriate to show pictures of children because the medicine is not for use in children.</li>
        <li>Product ads must identify the drug's brand name and the generic name. The brand name is the name the company gives its drug. The generic name is a name of the main chemical in the drug. In this case the generic name is misvastatium. (The names in this ad are made up as examples.)</li>
        <li>The ad must give an FDA-approved use for the drug. Any claims made must be supported by evidence or clinical experience. This ad appropriately states that Arbitraer is approved to treat seasonal allergy symptoms.</li>
        <li>The ad directs the reader to seek a doctor's advice about taking Arbitraer. The drug company includes this to ensure that a consumer will not think he or she is qualified to make the prescribing decision.</li>
        <li>Product claim ads often list a website and toll-free telephone number for people who want more information.</li>
        <li>Product ads should say that the drug is given by prescription only. This ad appropriately states that Arbitraer is a prescription drug.</li>
        <li>Print ads must include a brief summary of the risks listed in the drug's FDA-approved prescribing information. The brief summary contains one or more pages of important information about a drug's risks. </li>
        <li>The ad must include a "fair balance" of information about the risks and benefits of the drug. The ad as a whole does not put more emphasis on the drug's benefits than its risks. </li>
        <li>Ads appearing in print (such as magazines or newspapers) have to include the statement "You are encouraged to report negative side effects of prescription drugs to the FDA."</li>
      </ol>
    </div>
  );
};

const ShowAnswers = ({ handleClick }: { handleClick: () => void }): JSX.Element => {
  return (
    <div className="hidden md:block mt-10">
      <h2 className="text-2xl">Answer Key</h2>
      <div className="flex flex-col space-y-3">
        <Button
          action="primary"
          className="my-5"
          color="indigo"
          onClick={handleClick}
          title="Show answers"
        >
          Check Your Answers
        </Button>
        <div className="flex items-center space-x-2">
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-lime-500 text-4xl font-bold text-black p-2" />
          <span>You selected the correct answer.</span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex items-center justify-center w-12 h-12 rounded-full bg-red-500 text-4xl font-bold text-black p-2" />
          <span>You selected an incorrect answer. The correct answer is now displayed instead.</span>
        </div>
      </div>
    </div>
  );
};

const SmallScreen = (): JSX.Element => {
  return (
    <div className="block md:hidden">
      <Note headerText="Important" type="warning" withIcon>
        <p>This activity does not work on phones. Please use a tablet or computer to complete this activity.</p>
        <p className="mt-5">The instructions and requirements list are still available on phones, however, the advertisement will not display.</p>
      </Note>
    </div>
  );
};

const PrescriptionDrugAdPage = (): JSX.Element => {
  const [showCorrect, setShowCorrect] = useState<boolean>(false);
  const adRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setShowCorrect(true);

    if (adRef?.current) {
      adRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="bg-gray-100 p-10">
      <H1 underline>Prescription Drug Ad Activity</H1>
      <SmallScreen />
      <Instructions />
      <RequirementsList />
      <Ad showCorrect={showCorrect} ref={adRef} />
      <ShowAnswers handleClick={handleClick} />
    </div>
  );
};

export default PrescriptionDrugAdPage;

const Arbitraer = ({ showCorrect }: HasInputs): JSX.Element => {
  return (
      <div className="bg-drug-ad-blue pt-8 pl-5 flex justify-end items-end mt-5 max-w-xl">
        <h1 className="text-5xl font-bold text-white tracking-tight relative"><Input className="absolute left-0 -ml-12" correct="2" showCorrect={showCorrect} />Arbi<span className="text-black">traer</span></h1>
      </div>
  );
};

